#Footer{
  background-color: black;
  padding-top: 10px;
  padding-bottom: 50px;
  height: 100px;
  background-color: black;
}
#icons-nav{
  background-color: black;
  display: flex;
  justify-content: center;
}

.link-icons{
  margin:15px;
  width: 50px;
  height: auto;
  transition: transform .2s; /* Animation */
  color:#e45f05;
}
.link-icons:hover{
  transform: scale(1.3);
}

@media all and (min-width: 300px) and (max-width: 750px) {
  .link-icons{
    width:40px;
    margin:8px;
  }
}
