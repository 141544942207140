#contact-header{
  text-align: left;
  padding-top: 30px;
  color: #e45f05;
  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: 700;
}
#logoImg{
  opacity: 0.1;
  position: absolute;
  right:100px;
  width: 'auto';
  height: 100%;
}
.contact{
  overflow: hidden;
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
}
#form{
  justify-content: center;
  padding-top: 30px;
  
}
#form-input{
  /* margin-bottom: 20px; */
  font-family: 'Montserrat';
  font-weight: 500;
 
}
#form-message{
  margin-inline: 20px;
  margin-bottom: 20px;
  font-family: 'Montserrat';
  font-weight: 500;
}
#formBtn{
  background-color: #e45f05;
  color: #fff;
  font-size: 18px;
  font-family: 'Montserrat';
  width: '80%';
  margin:5px;
}
#cvDownload:hover{
  text-decoration: none;
}


@media all and (min-width: 400px) and (max-width: 1000px) {
  #form-input{
    margin-left: 0px;
    margin-right: 0px;
  }
}
