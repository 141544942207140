#navbar{
  /* background-color: #252b31; */
  background-color: #000;
}
#title {
  display: flex;
  flex-grow: 1;
  align-self: flex-end;
  }
  #navBtn{
    color: #fff;
    font-size: 18px;
    font-family: 'Montserrat';
  }
  #navBtn:hover, #navBtn:focus{
    color:#e45f05;
  }

  @media all and (min-width: 300px) and (max-width: 350px) {
#logoimg{
  height: 70px; 
    }
  }
