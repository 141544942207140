.about {
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: hidden;
  position: relative;
}

#bgMapImg {
  opacity: 0.11;
  position: absolute;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

#about-header {
  padding-top: 30px;
  text-align: center;
  color: #e45f05;
  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: 700;
}

#background-text {
  margin-right: 20px;
  margin-left: 20px;
  /* display: flex;
  justify-content: center; */
  margin-bottom: 15px;
  line-height: 1.5;
  font-family: 'Montserrat';
  font-weight: 500;
}

#background-header,
#toolbelt-header {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 40px;
  margin-top: 30px;
}

#background-header {
  text-align: center;
}

.toolbelt {
  margin-top: 40px;
}

@media all and (min-width: 300px) and (max-width: 750px) {
  #background-text {
    font-size: 18px;
    margin-left: 5px;
    margin-right: 5px;
  }

  #about-header {
    padding-top: 10px;
  }

  #background-header {
    font-size: 30px;
  }
}