
.work{
  padding-top: 30px;
  padding-bottom: 100px;
  background-color: #000;
}
.project-links{
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-right: 60px;
}
#project-desc{
  padding-top: 20px;
   font-family: 'Montserrat';
  font-weight: 500;
  color:#fff;
}
#title{
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 35px;
  color:#fff;
}
#subtitle{
  font-family: 'Montserrat';
  font-weight: 700;
  color:#fff;
  font-size: 18px;
  color:#fff;
}
#testText{
  font-family: 'Montserrat';
  font-size: 16px;
  color: #e45f05;
  padding-top: 10px;
}
#projectImg{
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: white;
  background-color: black;
  padding:20px;
  width:auto;
  height:300px;
}
#projectBtn{
  background-color: #e45f05;
  color: #fff;
    font-size: 18px;
    font-family: 'Montserrat';
}
#projectBtn:hover, #projectBtn:focus{
  text-decoration: underline;
}
#work-header{
  padding-top: 30px;
  color: #e45f05;
  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: 700;
}
.carousel{
  padding-top: 30px;
}

#appBar{
  background-color: #000;
}
#modalToolbar{
  display: flex;
  justify-content: space-between;
}

@media all and (min-width: 300px) and (max-width: 750px) {
  #projectImg{
    height:auto;
    width:100%;
  }
  .carousel{
    margin-left: 10px;
    margin-right:10px;
  }
  #myFlixLogo{
    width: 150px;
  }
  #modalTitle{
    font-size: 20px;
    font-weight: 500;
    
  }
  #title{
    font-size: 24px;
    font-weight:500;
    color: #fff;
  }
  .work{
    padding: 5px;
  }
  .project-links{
    display: flex;
  justify-content: space-between;
   margin-right: 15px;
   
  }
  #projectBtn{
    font-size: 16px;
    margin: 5px;
  }

}