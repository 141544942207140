#Home {
  overflow: hidden;
  position: relative;
  padding-bottom: 10px;
}

.bgImg {
  opacity: 0.15;
  position: absolute;
  right: 400px;
  width: 70%;
  height: auto;
}

.home {
  padding-top: 130px;
  padding-bottom: 130px;
  background-color: #000;
  min-height: 610px;
}

.typist {
  margin-left: 90px;
  text-align: left;
  font-family: 'Montserrat';
  font-size: 24px;
}

.onetab {
  margin-left: 20px;
}

.twotab {
  margin-left: 30px;
}

.gray {
  color: gray;
  font-style: italic;
}

.white {
  color: #fff;
}

.pink {
  color: #df769b;
  font-weight: bolder;
}

.orange {
  color: #e56332;
}

.italic {
  color: #e56332;
  font-style: italic;
}

.blue {
  color: #129ab5;
}

.yellow {
  color: #e4b781;
}

.green {
  color: #49e9a6;
}

@media all and (min-width: 300px) and (max-width: 750px) {
  .typist {
    margin-left: 0px;
    font-size: 20px;
    margin-top: 60px;
    padding-bottom: 100px;
  }

  .home {
    height: 700px;
  }
}